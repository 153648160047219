import React from "react";
import DocumentTitle from "react-document-title";
import { enquireScreen } from "enquire-js";
import Header from "./Header";
import Banner from "./Banner";
import Feature from "./Feature";
import FAQ from "./FAQ";
import Footer from "./Footer";
import "./static/style";
import { reportPage } from "../utils/tools";

let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});

class Home extends React.PureComponent {
  state = {
    isMobile,
    showShadow: false,
    isBgWhite: false,
    activeHome: false,
    activeProduct: false,
    activefaq: false,
    isScroll: false,
  };

  componentDidMount() {
    reportPage();
    
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
    window.addEventListener("scroll", this.bindScroll);
  }

  bindScroll = (event) => {
    this.setState({
      isScroll: true,
    });
    const scrollTop =
      (event.srcElement ? event.srcElement.documentElement.scrollTop : false) ||
      window.pageYOffset ||
      (event.srcElement ? event.srcElement.body.scrollTop : 0);
    if (scrollTop > 0) {
      this.setState({
        isBgWhite: true,
      });
    } else {
      this.setState({
        isBgWhite: false,
      });
    }

    // const homeOT = document.getElementById('home').offsetTop - 64;
    const producteOT =
      document.getElementById("product").offsetTop - window.innerHeight / 2;
    const faqOT =
      document.getElementById("FAQ").offsetTop - window.innerHeight / 2;
    if (scrollTop < producteOT) {
      this.setState({
        activeHome: false,
        activeProduct: false,
        activefaq: false,
      });
    } else if (producteOT < scrollTop && scrollTop < faqOT) {
      this.setState({
        activeHome: false,
        activeProduct: true,
        activefaq: false,
      });
    } else if (scrollTop > faqOT) {
      this.setState({
        activeHome: false,
        activeProduct: false,
        activefaq: true,
      });
    }
  };
  navToShadow = (e) => {
    this.setState({ showShadow: e.mode === "leave" });
  };
  jumpToSection = (id) => {
    let element = document.getElementById(id);
    if (element) {
      // 64 header高度
      window.scrollTo(0, element.offsetTop - 64);
    }
  };

  render() {
    return [
      <Header
        key="header"
        isScroll={this.state.isScroll}
        activeHome={this.state.activeHome}
        activeProduct={this.state.activeProduct}
        activefaq={this.state.activefaq}
        handleclick={this.jumpToSection}
        className={this.state.isBgWhite ? "btn-white" : ""}
        isMobile={this.state.isMobile}
      />,
      <Banner
        key="banner"
        isMobile={this.state.isMobile}
        navToShadow={this.navToShadow}
      />,
      <Feature key="feature" isMobile={this.state.isMobile} />,
      <FAQ key="FAQ" isMobile={this.state.isMobile} />,
      <Footer key="footer" isMobile={this.state.isMobile} />,
      <DocumentTitle title="OKPeso" />,
    ];
  }
}
export default Home;
