import React, { PureComponent } from "react";
import { UAParser } from 'ua-parser-js';



export class TestUserAgent extends PureComponent {
  state = {
    testUa: '',
    vendor: ''
  };

  props = this.props;
  componentDidMount() {

  }

  handleChange = (e) => {
    this.setState({
      testUa: e.target.value
    });

    const parser = new UAParser(e.target.value).getDevice();
    const {vendor} = parser
    this.setState({
      vendor
    })
  }

  

  render() {
    const {
        testUa,
        vendor
    } = this.state;
    
    console.log(vendor,'vendor');
    return (
      <div
       
      >
        TEST UserAgent:
        <input style={{border: '1px solid blue'}} value={testUa}   onChange={this.handleChange}/>
        <div>
          GET Device ：
          <span styles={{color: 'green'}}>{vendor || 'undefined'}</span>
        </div>
      </div>
    );
  }
}

export default TestUserAgent;
