import React from 'react';
import QueueAnim from 'rc-queue-anim';
import { Row } from 'antd';
import { page1 } from './data';
import websiteBg from '../assets/imgs/website-bg2.png';
import girlBgImg from '../assets/imgs/website-girl.png';

export default function Page1(props) {
  const data1 = page1.slice(0, 2);
  const data2 = page1.slice(2, 4);
  const renderCard = (data) => {
    const cardHtml = data.map((card, i) => (
      <div className="item-feature" key={i.toString()}>
        <img src={card.img} className="cardImg" alt='card'/>
        <svg className="cardIcon">
          <use xlinkHref={card.icon} />
        </svg>
        <p className="cardTitle">{card.title}</p>
        <p className="cardDetail">{card.description}</p>
        {
          ((i + 1) === page1.length && !props.isMobile) ? (
            <img src={girlBgImg} className="girlImg" alt='card'/>
          ) : null
        }
      </div>
    ));
    return cardHtml;
  }

  const children = renderCard(page1);
  const children1 = renderCard(data1);
  const children2 = renderCard(data2);

  return (
    <section id="product" className="page-wrapper page1">
      {!props.isMobile ? <img src={websiteBg} className="page1-bg-img" alt="banner" /> : null}
      
      <div className="page-main-content">
        <p className="fsTitle">{'Features'}</p>
        <p className="fsDesc">{'Our objective is to make loans easier, help more needy users, and build rapport.'}</p>
        {
          !props.isMobile ? (
            <QueueAnim
              component={Row}
              type="bottom"
              className="page row text-left one-row-page"
              delay={500}
            >
              {children}
            </QueueAnim>) : (
              <div>
                <QueueAnim
                  component={Row}
                  type="bottom"
                  className="page row text-left one-row-page"
                  delay={500}
                >
                  {children1}
                </QueueAnim>
                <QueueAnim
                  component={Row}
                  type="bottom"
                  className="page row text-left one-row-page clear-padding"
                  delay={500}
                >
                  {children2}
                </QueueAnim>
              </div>
            )
        }
      </div>
      {
        props.isMobile ? (
          <div className="page-mobile-bg-wrapper">
            <img src={websiteBg} className="page1-mobile-bg-img" alt="" />
            <img src={girlBgImg} className="mobile-girlImg" alt=''/>
          </div>) : null
      }
    </section>);
}
