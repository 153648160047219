import websiteFaster from '../assets/imgs/website-faster.png';
import websiteSafer from '../assets/imgs/website-safer1.png';
import websiteEasier from '../assets/imgs/website-easier.png';
import websiteFriend from '../assets/imgs/website-friend.png';
import faq1Img from '../assets/imgs/website-faq1.png';
import faq2Img from '../assets/imgs/website-faq2.png';
import faq3Img from '../assets/imgs/website-faq3.png';


export const page1 = [
  {
    img: websiteFaster,
    icon: '#iconzu5',
    title: 'FASTER',
    description: '1 ID, 1 mobile number, loan released in 2 hours to 24 hourss',
  },
  {
    img: websiteSafer,
    icon: '#iconzu2',
    title: 'SAFER',
    description: 'Encrypted personal information to ensure security, only used for review and certification',
  },
  {
    img: websiteEasier,
    icon: '#iconzu4',
    title: 'EASIER',
    description: 'Multiple sources (ML，RD Pawnshop),easy as buying a cola',
  },
  {
    img: websiteFriend,
    icon: '#iconzu3',
    title: 'MORE LIKE A FRIEND',
    description: 'A powerful team always ready to serve you in time of difficulty',
  },
];

export const page3 = [
  {
    img: faq1Img,
    title: 'What qualifications are required？',
    descriptions: [
      '1) A Phone number in use',
      '2) Valid government ID',
      '3) Stable income',
      '4) Age between 18-49 years old'
    ],
  },
  {
    img: faq2Img,
    title: 'How to apply a loan? ',
    descriptions: [
      '1) Install OKPeso and register with your own phone number.',
      '2) Fill out peso loan application form.',
      '3) Wait for cash loan approval.',
      '4) Claim your personal loan.'
    ],
  },
  {
    img: faq3Img,
    title: 'How to repay your cash loan?',
    descriptions: [
      '1)M Lhuillier:Visit M Lhuillier and fill the repay form.',
      '2)7-Eleven: Go to 7-Eleven and repay via CLiQQ machine or CLiQQ app.',
      '3)GCash,Coins: Open app, click Pay Bills to access Loans option to repay.',
      '4)Bank: Deposit money to our corporate bank account given in the app.',
      '5)ECPAY: Go to ECPAY, fill out needed information in Bills Payment Form, reimburse your personal loans and receive a payment confirmation receipt.'
    ]
  },
];


export const faq = [
  {
    tabkey: 0,
    tabTitle: 'Loan application',
    DescriptionProblem: [
      {
        question: 'How do you apply for a loan?',
        answer: [
          '1) Install OKPeso and register with your own phone number.',
          '2) Fill out peso loan application form.',
          '3) Wait for cash loan approval.',
          '4) Claim your personal loan.',
          'Please follow all stages of applying for loans in the application and ensure the validity of all your information.'
        ]
      },
      {
        question: 'Why do I receive a security warning when installing the APK installation package downloaded from the official website? Can I trust it?',
        answer: [
          `Please be assured that the installation packages downloaded from our official website are safe and reliable. We guarantee that all installation packages have undergone rigorous security checks and do not contain any malware or viruses. You can download and install them with confidence, without worrying about any security warnings.`,
        ]
      },
      {
        question: `Why can't I receive a verification code?`,
        answer: [
          `Please ensure that your cellphone is running well, there may be some delays when your operator's server is busy. Please try again `,
        ]
      },
      {
        question: 'How do I receive my loan?',
        answer: [
          `Once your loan application is approved, your loan will be disbursed to your registered bank account, or alternatively, you may receive it through your electronic wallet or via other offline withdrawal methods. Depending on the service provider and the recipient's preferences, the funds can be sent to various destinations, including a bank account, an RD Pawnshop, or e-wallet services such as GCash or PayMaya.`,
        ]
      },
      {
        question: 'Once approved, how long will it take for the funds to be disbursed?',
        answer: [
          `Upon approval, the funds will be disbursed to your designated bank account or e-wallet within a 24-hour timeframe.`,
        ]
      },
      {
        question: 'After submitting a loan, can it be canceled?',
        answer: [
          `After the loan application is sent, it will be processed automatically by the system and cannot be canceled.`,
        ]
      },
      {
        question: 'What are the eligibility requirements for applying (age, income, occupation)?',
        answer: [
          `The conditions for applying for a loan typically include the following for Filipino citizens aged 20to 49: possession of a stable job, steady income, and fulfillment of the age requirement.`,
        ]
      },
      {
        question: 'What documents or proof do I need to provide?',
        answer: [
          `You will only need to provide a government-issued ID and complete the application with your accurate and basic personal information.`,
        ]
      },
    ]
  },
  {
    tabkey: 1,
    tabTitle: 'Loan Amount',
    DescriptionProblem: [
      {
        question: 'What is the minimum and maximum loan amount I can apply for?',
        answer: [
          'The loan amount we offer ranges between PHP 500 and PHP 30,000, with the final amount you can receive being determined by your eligibility assessment and credit score.',
        ]
      },
      {
        question: `Are there any restrictions on the loan amount?`,
        answer: [
          `Yes, there are limits based on your creditworthiness and repayment capacity.`,
        ]
      },
      {
        question: `Can my loan limit be raised?`,
        answer: [
          `Yes.As long as you maintain good credit and repay your loans early or on time, you will get a higher limit.`,
        ]
      },
      {
        question: `Can I apply for another loan if I have an existing loan?`,
        answer: [
          `Yes, as long as you maintain good credit and repay your loans early or on time, you will be eligible to borrow money. Within your credit limit, you can have multiple loans at the same time, and these loans can be divided into several smaller borrowings.`,
        ]
      },
      
    ]
  },
  {
    tabkey: 2,
    tabTitle: 'Loan Fees',
    DescriptionProblem: [
      {
        question: 'What is the interest rate? Is it a fixed or variable rate?',
        answer: [
          `The exact rate and fees will be determined based on your credit score and other factors. For all the specific details related to your loan, including the interest rate, repayment terms, and any additional fees, please refer to your loan agreement. We strive to provide competitive rates and flexible terms to meet our customers' needs.`,
        ]
      },
      {
        question: `Under what circumstances might the interest rate change?`,
        answer: [
          `A significant improvement or deterioration in your credit score can lead to adjustments in the interest rate offered.`,
        ]
      },
      {
        question: `What are the additional fees during the loan process (e.g., service fees, management fees)?`,
        answer: [
          `The exact rate and fees will be determined based on your credit score and other factors. For all the specific details related to your loan, including the interest rate, repayment terms, and any additional fees, please refer to your loan agreement. We strive to provide competitive rates and flexible terms to meet our customers' needs.`,
        ]
      },
      {
        question: `Can these fees be paid in installments?`,
        answer: [
          ` No, these fees are deducted from the loan amount before disbursement and cannot be paid in installments.`,
        ]
      },

    ]
  },
  {
    tabkey: 3,
    tabTitle: 'Repayment period',
    DescriptionProblem: [
      {
        question: 'What is the minimum and maximum repayment period?',
        answer: [
          'You can choose a repayment period from 7 days to 210days , depending on the loan amount and type.',
        ]
      },
      {
        question: `Can I adjust my loan period?`,
        answer: [
          `I'm sorry, but the loan term you have selected cannot be changed. Please adhere to the payment terms indicated in your app. If you wish to reapply and request a longer term, you can fill out a feedback form on the app. We will reassess your repayable term, and if there are any changes, we will notify you via SMS.`,
        ]
      },
      {
        question: `Can I repay the loan early? Are there any fees for early repayment?`,
        answer: [
          `Yes, you can repay early without any penalties.`,
        ]
      },
    ]
  },
  {
    tabkey: 4,
    tabTitle: 'Issues of review',
    DescriptionProblem: [
      {
        question: 'How long does the loan approval process take?',
        answer: [
          'The approval process generally takes 1-24 hours. Once approved, you will receive a notification via SMS and through the app.',
        ]
      },
      {
        question: `If my application is denied, how will I know the reason?`,
        answer: [
          `If the documents you submitted do not meet the requirements, we will send you a reminder via SMS and an app push notification to resubmit your application.`,
        ]
      },
      {
        question: `Why is my loan application not approved?`,
        answer: [
          `We understand that it can be disappointing to learn that your loan application has been denied. However, please know that our loan decisions are based on OKPeso's credit and underwriting criteria, which take into account various factors in your application.`,
        ]
      },
      {
        question: `If my loan is not approved, can I apply again?`,
        answer: [
          `Yes, if your loan application does not pass our review, please maintain a good credit record. You are welcome to try applying again on our platform using your original account after a waiting period of 30 days.`,
        ]
      },
    ]
  },
  {
    tabkey: 5,
    tabTitle: 'Repayment',
    DescriptionProblem: [
      {
        question: 'What are the available repayment methods (e.g., bank transfer, e-wallet, auto-debit)?',
        answer: [
          'You can repay your loan via bank transfer, e-wallet, or Other(7-11;ML;Shopeepay). Choose the method that suits you best.',
        ]
      },
      {
        question: `Can I make partial payments, or do I have to repay in full?`,
        answer: [
          `You can choose to repay the entire loan amount in one lump sum before the due date, or alternatively, you can apply for an extension of the repayment period in advance. Please note that an extension fee will be applicable for the latter option.`,
        ]
      },
      {
        question: `I already made a payment. How long is the posting of my loan payment?`,
        answer: [
          `Loan payment verification is typically completed within 1 hour of your payment. If your payment status remains unchanged after this time, kindly reach out to our customer service for further handling.`,
        ]
      },
      {
        question: `Will I receive payment reminders?`,
        answer: [
          `We will send a message to remind you of your payments on and before your due date.`,
        ]
      },
      {
        question: `I have made a payment, but there is an overpayment. Can I file a refund?`,
        answer: [
          `We will process a refund of your overpayment to your account. kindly reach out to our customer service for further handling.`,
        ]
      },
      {
        question: `I made a wrong payment? What's the solution?`,
        answer: [
          `If you made a wrong payment, we can assist you in adjusting your account. Please contact our customer service to provide feedback about your issue.`,
        ]
      },
      {
        question: `What happens if I miss a payment?`,
        answer: [
          `If you miss a repayment deadline, you'll face a late fee and your credit score will drop. It's best to repay on time to avoid these penalties.`,
        ]
      },

    ]
  },
  {
    tabkey: 6,
    tabTitle: 'Loan Security',
    DescriptionProblem: [
      {
        question: 'How will my personal and loan information be protected?',
        answer: [
          'We prioritize the safeguarding of your privacy and employ industry-leading encryption techniques to secure your personal and financial data.',
        ]
      },
      {
        question: `Does the app comply with privacy and data security regulations?`,
        answer: [
          `Yes, our app adheres strictly to all pertinent privacy and data security regulations, encompassing the Philippines' Data Privacy Act and other international standards, ensuring the highest level of protection for user information.`,
        ]
      },

    ]
  },
  {
    tabkey: 7,
    tabTitle: 'Other Questions',
    DescriptionProblem: [
      {
        question: 'Can I change my telephone number and ID card information? ',
        answer: [
          'To ensure the authenticity and security of identity information, we are currently unable to directly facilitate changes to ID cards. However, if the mobile phone number you use has changed, you can contact our customer service team and provide the corresponding identification documents along with your new number to make the necessary modifications.',
        ]
      },
      {
        question: `How can I request a coupon?`,
        answer: [
          `OKPeso will regularly launch various activities, and you can participate in them to obtain coupons. If you have taken out multiple loans and maintained a good repayment record, you can also actively reach out to our customer service team. We will submit a request to our superiors to send you coupons as a reward.`,
        ]
      },
      {
        question: `Will applying for a loan affect my credit score?`,
        answer: [
          `Yes, applying for a loan may affect your credit score, as your application will be reported to CIC`,
        ]
      },
      {
        question: `If I repay on time, can it help improve my credit score?`,
        answer: [
          `Consistent and punctual repayments positively influence your credit score, bolstering your prospects for future loan approvals and potentially resulting in an increase in your credit limit.`,
        ]
      },
      {
        question: `Who should I contact if I have questions or need help?`,
        answer: [
          `You can reach us through the following contact details:`,
          `Globe: 09052553180`,
          `Smart: 09319533595(whats app) or 09621546377 (viber)`,
          `Email: service@okpeso.com`,
          `Facebook: facebook.com/RealOKPeso/`,
        ]
      },
      {
        question: `What are the customer support hours and contact details?`,
        answer: [
          `Our customer support is available during Monday to Sunday, 9:00 AM to 6:00 PM, including public holidays. Please note that the customer support hours may vary, so it's best to check our website or social media pages for the most up-to-date information.`,
        ]
      },
    ]
  },
]
